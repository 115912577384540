import { forwardRef } from 'react';
import { IconProps } from '../types';

export const FluentArrowCounterclockwiseIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                {...props}
                ref={forwardedRef}
            >
                <path
                    fill={color}
                    d="M16 10A6 6 0 0 0 5.528 6H7.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v1.601a7 7 0 1 1-1.98 4.361a.5.5 0 0 1 .998.076A6 6 0 1 0 16 10"
                />
            </svg>
        );
    },
);

export default FluentArrowCounterclockwiseIcon;

import { forwardRef } from 'react';
import { IconProps } from '../types';

export const InvertColorIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                ref={forwardedRef}
            >
                <g clipPath="url(#a)">
                    <path
                        d="M6.94181 14C5.31523 14 3.93337 13.4433 2.79626 12.3298C1.65914 11.2163 1.09058 9.86045 1.09058 8.26241C1.09058 7.47219 1.24748 6.74164 1.56129 6.07076C1.8751 5.39987 2.26747 4.80004 2.73841 4.27126L6.94135 0L11.1671 4.29403C11.6228 4.82281 12.0076 5.42788 12.3214 6.10924C12.6352 6.7906 12.7921 7.50901 12.7921 8.26446C12.7921 9.86341 12.2237 11.2191 11.0869 12.3314C9.95009 13.4438 8.56839 14 6.94181 14ZM6.94135 13.3414V0.986288L3.21186 4.74994C2.7523 5.23409 2.39363 5.77509 2.13585 6.37295C1.87806 6.97081 1.74916 7.59995 1.74916 8.26036C1.74916 9.67227 2.25396 10.8721 3.26355 11.8598C4.27314 12.8475 5.49908 13.3414 6.94135 13.3414Z"
                        fill={color}
                    />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill={color} d="M0 0H14V14H0z" />
                    </clipPath>
                </defs>
            </svg>
        );
    },
);

export default InvertColorIcon;

import { forwardRef } from 'react';
import { IconProps } from '../types';

export const FluentChevronDownIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                {...props}
                ref={forwardedRef}
            >
                <path
                    fill={color}
                    d="M15.854 7.646a.5.5 0 0 1 .001.707l-5.465 5.484a.55.55 0 0 1-.78 0L4.147 8.353a.5.5 0 1 1 .708-.706L10 12.812l5.147-5.165a.5.5 0 0 1 .707-.001"
                />
            </svg>
        );
    },
);

export default FluentChevronDownIcon;

import { isEmpty, isObject } from '../utils';

export const DECIMAL_PLACES = 2;
export const THOUSAND_SEPARATOR = 3;

export const isNumericDuration = (value?: unknown) => !Number.isNaN(Number(value));

export const isNumeric = (value?: unknown) => {
    if (isObject(value) || isEmpty(value)) {
        return false;
    }
    // removing '.' representing thousands
    let result = `${value as string}`.split('.').join('');
    // removing ':' representing a duration;
    result = result.split(':').join('');
    // removing '%' from end of string
    result = result.replace('%', '');
    // replace ',' with '.'
    result = result.replace(',', '.');

    return !Number.isNaN(Number(result));
};

const getNumbersParts = (number: number | string): string[] => {
    const unitsAndDecimalPlaces = String(number).split(',');

    if (unitsAndDecimalPlaces.length <= 1) {
        const thousandUnits = unitsAndDecimalPlaces[0]?.split('.');

        // check if we have decimal places when separator is "."
        if (thousandUnits.length === 2) {
            return thousandUnits;
        }

        return [thousandUnits.join('')];
    }

    if (unitsAndDecimalPlaces.length === 2) {
        return [unitsAndDecimalPlaces[0].split('.').join(''), unitsAndDecimalPlaces[1]];
    }

    return [`${number}`];
};

export const truncateDecimalPlaces = (
    number: number | string,
    precision: number = DECIMAL_PLACES,
): [boolean, number] => {
    const d = 10 ** precision;
    const truncatedValue = Math.round((+number + Number.EPSILON) * d) / d;

    return [truncatedValue === 1, truncatedValue];
};

const roundDecimalPlaces = (number: number | string, decimalPlaces: number = DECIMAL_PLACES): [boolean, string] => {
    const [overflow, truncatedValue] = truncateDecimalPlaces(number);
    const roundedNumber = String(truncatedValue).slice(decimalPlaces).padEnd(decimalPlaces, '0');

    return [overflow, roundedNumber];
};

export const decorateNumeric = (number?: number | string): string => {
    if (typeof number === 'undefined') {
        return '0';
    }

    if (+number < 0) {
        return `-${decorateNumeric(Math.abs(+number))}`;
    }

    let [unitPart, decimalPart] = getNumbersParts(number);

    if (!decimalPart && !unitPart) {
        return '0';
    }

    if (decimalPart) {
        const [overflow, newDecimalPart] = roundDecimalPlaces(+`0.${decimalPart}`);
        decimalPart = newDecimalPart;
        if (overflow) {
            unitPart = `${+unitPart + 1}`;
        }
    }

    const mod = unitPart.length % THOUSAND_SEPARATOR;
    const newLength =
        unitPart.length % THOUSAND_SEPARATOR === 0 ? unitPart.length : unitPart.length + (THOUSAND_SEPARATOR - mod);

    unitPart = unitPart.padStart(newLength, '0');

    let finalString = '';
    for (let i = unitPart.length; i > 0; i -= THOUSAND_SEPARATOR) {
        finalString = `.${unitPart.slice(i - THOUSAND_SEPARATOR, i)}${finalString}`;
    }

    return `${finalString.replace(/^\.0{0,2}/, '')}${decimalPart ? `,${decimalPart}` : ''}`;
};

export const decoratePercentage = (number: number | string): string => `${decorateNumeric(number)}%`;

export const randomIntegerBetween = (start: number, stop: number) => {
    return start + Math.floor(Math.random() * stop);
};

export const randomIntegerInclusive = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
};

export const truncateNumber = (value: number): number => {
    return value < 0 ? Math.ceil(value) : Math.floor(value);
};

type PreventableEvent = {
    preventDefault?: () => void;
    stopPropagation?: () => void;
};

export const preventEvent = (ev: PreventableEvent) => {
    ev?.preventDefault?.();
    ev?.stopPropagation?.();
};

export const triggerKeyboardEvent = (node: HTMLElement, eventType: string, options = {}) => {
    const event = new KeyboardEvent(eventType, options);
    node?.dispatchEvent?.(event);
};

export const triggerMouseEvent = (node: HTMLElement, eventType: string, options = {}) => {
    const event = new MouseEvent(eventType, options);
    node?.dispatchEvent?.(event);
};

export const wasMultiSelectKeyUsed = (ev: KeyboardEvent) => ev?.shiftKey;

export const wasToggleInSelectionGroupKeyUsed = (ev: KeyboardEvent) => {
    const isUsingWindows = navigator?.platform?.includes?.('Win');
    return isUsingWindows ? ev?.ctrlKey : ev?.metaKey;
};
